<template v-if="data">
    <div class="row result-block">
        <h1>{{ data.title }}</h1>
        <table :id="type">
            <tr v-for="(item) in data.data" :key="item.title">
                <td>{{ item.title }}</td>
                <td >{{ item.value }}</td>
            </tr>
        </table>
    </div>
    
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    data: Object,
    type: String
});

const type = props.type;
const data = computed(() => props.data);
</script>

<style scoped>
.result-block{
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease;
    margin-bottom: 20px!important;
}
table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,th {
    border: 1px solid #ddd;
    padding: 7px;
    transition: background-color 0.3s ease;
}

tr:nth-child(even){
    background-color: #f9f9f9;
}

th {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    background-color: #007bff;
    color: white;
}

tr:hover td {
    background-color: #e9ecef;
}
h1 {
    font-family: 'Arial', sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    font-weight: lighter;
    animation: fadeInUp 1s ease;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
