<template>
  <div class="col-md-6">
    <form @submit.prevent="submitForm" class="my-form">
      <div v-for="(element , index) in formStructure" :key="index" class="form-group">
          <label :for="element.name" class="form-label">{{ element.label }}</label>
          <template v-if="element.tag === 'select'">
            <select :name="element.name" class="form-control" v-model="dataForm[element.name]">
              <option v-for="(option , index) in element.options" :key="index" :value="option.value" class="form-option">{{ option.text }}</option>
            </select>
            
          </template>
          <template  v-else-if="element.tag === 'input'">
            <input :name="element.name" :type="element.type" class="form-control"  v-model="dataForm[element.name]" placeholder/>
          </template>
          <div v-if="errors[element.name]" class="invalid-feedback" :style="{display : errors[element.name] ? 'block' : 'none'}">{{ errors[element.name] }}</div>
      </div>
      <button type="submit" class="submit-button btn btn-primary">Submit</button>
    </form>
  </div>
</template>

<script setup>
  import {ref, defineProps, defineEmits} from 'vue'
  const props = defineProps({
    formStructure: Array
  })
  const dataForm = ref({})
  const errors = ref({})
  const formStructure = props.formStructure
  const emit = defineEmits(['form-submit'])
  const validateForm = ()=>{
    errors.value={}
    formStructure.forEach((element)=>{
      if(element.required && !dataForm.value[element.name])
        errors.value[element.name] = `Valid ${element.label} is required.`
    })
    return Object.keys(errors.value).length === 0
  }
  const submitForm = ()=>{
    validateForm() && emit('form-submit', dataForm.value)
  }
</script>

<style scoped>
  .my-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.form-group {
    margin-bottom: 25px;
}

.form-label {
    font-weight: lighter;
    color: #333333;
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
}

.form-control {
    width: 100%;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #007bff;
    outline: none; /* Remove default outline */
}

.submit-button {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

/* Adjustments for better readability and aesthetics */
.my-form h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #007bff;
}

.form-control::placeholder {
    color: #999999;
}

.form-control:focus::placeholder {
    color: transparent; /* Hide placeholder text on focus */
}

</style>
