<template>
  <div class="container" style="scale: 0.7">
    <div class="row">
      <SimForm v-if="formStructure" :form-structure="formStructure" @form-submit="handleSubmit" :class="{'col-md-6':resultLoaded, 'col-md-12':!resultLoaded}" />
      <div :class="{'col-md-6': true,result : recap? true : ''}">
        <Result v-if="recap" :data="recap" type="recap"/>
        <Result v-if="taxResult" :data="taxResult" type="tax"/>
      </div>
    </div>
  </div>
  
</template>

<script setup>
    import SimForm from './views/SimForm.vue'
    import Result from './views/Result.vue'
    import {ref } from 'vue';
    import axios from 'axios'
    const formStructure = ref(null)
    const taxResult = ref(null)
    const recap = ref(null)
    const resultLoaded = ref(false)

    const handleSubmit = async (formData)=>{
      const response  = await axios.post('https://simulateur-rcg-beta.dc1.smart-labs.tech/api/process/estimate',formData, {
          headers: {
              'Content-Type': 'application/json',
              'accept':'application/json'
          }
      })
      taxResult.value = response.data.tax
      recap.value = response.data.recap
      resultLoaded.value = true
    }
    const getFormStructure = async ()=>{
      const response = await axios.get('https://simulateur-rcg-beta.dc1.smart-labs.tech/api/process/getFormStructure')
      return response.data
    }
    const setup = async()=>{
      try{
        formStructure.value = await getFormStructure();
      }catch(ex){
        console.log(ex)
      }  
    }
    setup()
</script>

<style scoped>
.row{
  margin: 0;
}
  .container{
    height: 100vh;
    padding: 28px;
  }
  .result{
    max-width: 500px;
  }
  
</style>
./components/SimForm.vue
